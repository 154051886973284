import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { GetProductCostChangeHistoryModel } from "features/stock-ordering/core/domain/ProductCostChangeHistory.model";
import {
  GetProductCostChangeHistoryRepository,
  GetProductCostChangeHistoryResponse,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum GetProductCostChangeHistoryState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: GetProductCostChangeHistoryState;
  message: string;
  data: Array<GetProductCostChangeHistoryModel> | undefined;
}

const initialState: InitialState = {
  status: GetProductCostChangeHistoryState.initial,
  message: "",
  data: undefined,
};

export const getProductCostChangeHistory = createAsyncThunk(
  "getProductCostChangeHistory",
  async (query: string, { rejectWithValue }) => {
    try {
      const response: GetProductCostChangeHistoryResponse =
        await GetProductCostChangeHistoryRepository(query);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const getProductCostChangeHistorySlice = createSlice({
  name: "getProductCostChangeHistory",
  initialState,
  reducers: {
    resetGetProductCostChangeHistory: (state) => {
      state.status = GetProductCostChangeHistoryState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductCostChangeHistory.pending, (state) => {
        state.status = GetProductCostChangeHistoryState.inProgress;
      })
      .addCase(getProductCostChangeHistory.fulfilled, (state, action) => {
        if (action.payload) {
          const { message, data } = action.payload;
          state.status = GetProductCostChangeHistoryState.success;
          state.message = message;
          state.data = data;
        }
      })
      .addCase(getProductCostChangeHistory.rejected, (state, action) => {
        state.status = GetProductCostChangeHistoryState.fail;
        state.message = action.payload as string;
        state.data = undefined;
      });
  },
});

export const selectGetProductCostChangeHistory = (state: RootState) =>
  state.getProductCostChangeHistory;

export const { resetGetProductCostChangeHistory } =
  getProductCostChangeHistorySlice.actions;
export default getProductCostChangeHistorySlice.reducer;
