import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { useAppSelector } from "features/config/hooks";
import { Column } from "features/shared/presentation/components/data-table";
import { useEffect, useState } from "react";
import { selectGetStockOrderProducts } from "../slices/get-products.slice";
import { OrderTableData } from "features/stock-ordering/core/domain/order-table-row.model";
import { StockOrderHandleQuantity } from "./stock-order-handle-quantity";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import { FaRegTrashAlt } from "react-icons/fa";

interface StockOrderConfirmTableProps {
  isEdit: boolean;
  rows: OrderTableData[];
  setRows: (rowData: OrderTableData[]) => void;
  setTotalAmount: (value: number) => void;
  categoryName: string;
  isOrderAgain?: boolean;
}

const columns: Array<Column> = [
  { id: "prodId", label: "Product Id" },
  { id: "prodName", label: "Product Name" },
  { id: "uom", label: "UOM" },
  { id: "cost", label: "Cost  " },
  { id: "orderQty", label: "Order Qty  " },
  { id: "qtyPerPiece", label: "Qty per order" },

  { id: "itemTotalCost", label: "Total Cost" },
  { id: "trash", label: "" },
];

export function StockOrderConfirmTable(props: StockOrderConfirmTableProps) {
  const getProductInformation = useAppSelector(selectGetStockOrderProducts);
  const [unavailableProduct, setUnavaiableProduct] =
    useState<OrderTableData[]>();
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [totalCost, setTotalCost] = useState(0);

  const getUnavailableProduct = getProductInformation.data?.products
    .filter((product) => product.active === 0)
    .map((data) => data.product_id);

  useEffect(() => {
    const lastIndex = props.rows.length;
    const lastProduct = props.rows[lastIndex - 1];

    if (
      lastProduct?.productName === undefined ||
      lastProduct?.productName === ""
    ) {
      setAddButtonDisabled(true);
    } else {
      setAddButtonDisabled(false);
    }

    if (props.rows.length === 0) {
      setAddButtonDisabled(false);
      setTotalCost(0);
    }

    if (props.isOrderAgain) {
      const commonObjects = removeMatchingElements(
        props.rows,
        getUnavailableProduct
      );
      props.setRows(commonObjects);
    }
  }, [props.rows]);

  useEffect(() => {
    if (props.rows.length !== 0) {
      const updatedProdData = props.rows.map((prodData) => ({
        ...prodData,
        qty_per_order:
          prodData.orderQty === undefined
            ? 0
            : Number(prodData.orderQty) * Number(prodData.uom_multiplier),
        itemTotalCost:
          prodData.orderQty === undefined
            ? "0"
            : (Number(prodData.orderQty) * Number(prodData.cost)).toFixed(2),
      }));
      props.setRows(updatedProdData);
    }
  }, [props.rows.map((prod) => prod.orderQty).join()]);

  const removeMatchingElements = (
    arr1: OrderTableData[],
    arr2: string[] | undefined
  ) => {
    return arr1.filter(
      (item1) => !arr2?.some((item2) => item1.productId === item2)
    );
  };

  const handleProductChange = (
    value: {
      product_id: string;
      product_name: string;
      uom: string;
      cost: string;
      uom_multiplier: number;
    },
    rowsIndex: number
  ) => {
    const updatedRows = props.rows.map((r, index) => {
      if (index === rowsIndex) {
        const { product_id, product_name, uom, cost, uom_multiplier } = value;
        return {
          ...r,
          productId: product_id,
          productName: product_name,
          uom: uom,
          cost: cost,
          uom_multiplier: uom_multiplier,
        };
      }
      return r;
    });
    props.setRows(updatedRows);
  };

  const addRow = () => {
    setAddButtonDisabled(true);

    const defaultRow: OrderTableData = {
      productId: "",
      productName: "",
      uom: "",
      cost: "",
      orderQty: "",
      itemTotalCost: "",
      qty_per_order: 0,
      uom_multiplier: 1,
    };

    const updatedRows = [...props.rows, defaultRow];
    props.setRows(updatedRows);
  };

  const removeRow = (product_Id: string) => {
    const removedProduct = props.rows.find(
      (product) => product.productId === product_Id
    );
    if (removedProduct) {
      const updatedRows = props.rows.filter(
        (product) => product.productId !== product_Id
      );
      props.setRows(updatedRows);

      if (
        updatedRows.length > 0 &&
        updatedRows[updatedRows.length - 1].productId !== ""
      ) {
        setAddButtonDisabled(false);
      }
    }
  };

  return (
    <div>
      <div className="border-2 border-black rounded-lg pb-1 overflow-auto">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className="bg-black">
            <TableRow>
              {columns
                .filter((fltr) =>
                  props.isEdit
                    ? fltr.id !== "orderQty" &&
                      fltr.id !== "qtyPerPiece" &&
                      fltr.id !== "itemTotalCost"
                    : fltr.id !== "trash"
                )
                .map((row, index) => (
                  <TableCell key={index}>
                    <span className="text-white">{row.label}</span>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((productProperty, rowsIndex) => {
              const {
                productId,
                productName,
                uom,
                cost,
                orderQty,
                itemTotalCost,
                qty_per_order,
              } = productProperty;

              return (
                <TableRow key={rowsIndex}>
                  {/* {getUnavailableProduct?.includes(productId.toString()) ? (
                    <TableCell colSpan={6}>
                      <span>Unavailable</span>
                    </TableCell>
                  ) : (
                    <> */}
                  <TableCell sx={{ width: 100 }}>{productId}</TableCell>
                  <TableCell sx={props.isEdit ? { width: 400 } : null}>
                    {props.isEdit && getProductInformation.data ? (
                      <MaterialInputAutoComplete
                        colorTheme={"black"}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        options={getProductInformation.data.products.filter(
                          (item) => {
                            const excludedItems = props.rows.map(
                              (row) => row.productName
                            );
                            return (
                              !excludedItems.includes(item.product_name) &&
                              item.active === 1
                            );
                          }
                        )}
                        getOptionLabel={(option) => option.product_name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.product_name
                        }
                        placeholder={productName}
                        onChange={(event, value) => {
                          if (value) {
                            handleProductChange(value, rowsIndex);
                          }
                        }}
                        filterSelectedOptions
                      />
                    ) : (
                      productName
                    )}
                  </TableCell>
                  <TableCell sx={{ width: 75 }}>{uom}</TableCell>
                  <TableCell sx={{ width: 75 }}>{cost}</TableCell>
                  {props.isEdit !== true && (
                    <>
                      <TableCell sx={{ width: 125 }}>
                        <StockOrderHandleQuantity
                          rows={props.rows}
                          setRows={(rows) => props.setRows(rows)}
                          rowsIndex={rowsIndex}
                          currentValue={orderQty}
                          propertyKey={"orderQty"}
                          setTotalCost={(value) => {
                            props.setTotalAmount(value);
                            setTotalCost(value);
                          }}
                        />
                      </TableCell>
                      <TableCell>{qty_per_order}</TableCell>
                      <TableCell>{itemTotalCost}</TableCell>{" "}
                    </>
                  )}
                  {props.isEdit && (
                    <TableCell sx={{ width: props.isEdit ? 0 : 50 }}>
                      <FaRegTrashAlt
                        className="text-lg text-primary"
                        onClick={() => removeRow(productId)}
                      />{" "}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <div className="flex justify-between px-5 py-1">
          <div className="flex items-stretch justify-between w-full">
            <span className="text-base text-primary capitalize self-center ml-3">
              {props.categoryName}
            </span>

            {props.isEdit === false && (
              <span className="text-base capitalize self-center">
                <span className="font-semibold">Order Subtotal cost: </span>
                {totalCost.toFixed(2)}
              </span>
            )}
          </div>

          {props.isEdit && (
            <div className="flex space-x-3">
              <Button
                onClick={addRow}
                disabled={addButtonDisabled}
                fullWidth
                size="small"
              >
                Add
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
