import {
  REACT_APP_DOMAIN_URL,
  STOCK_ORDERING_MODAL_TITLE,
} from "features/shared/constants";
import {
  StockOrderProcessActionEnabler,
  StockOrderProcessFinancePayBilling,
  StockOrderTable,
  StockOrderingWatingSkeleton,
} from "../components";

import {
  GetProductDataState,
  getProductData,
  selectGetProductData,
} from "../slices/get-product-data.slice";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { createQueryParams } from "features/config/helpers";
import { getStockOrderStores } from "../slices/get-store.slice";
import {
  closePopupScroll,
  selectpopupScroll,
  togglePopupScroll,
} from "../slices/popup-scroll.slice";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { GetProductDataModel } from "features/stock-ordering/core/domain/get-product-data.model";
import { productDataInitialState } from "features/stock-ordering/core/productDataInitialState";
import { selectGetAdminSession } from "features/admin/presentation/slices/get-admin-session.slice";
import { getDeliverySchedule } from "../slices/get-delivery-schedule.slice";
import {
  closeModalScroll,
  selectModalScroll,
} from "../slices/modalScrollSlice.slice";

interface ProcessModalProps {
  open: boolean;
  onClose: () => void;
  currentTab: number;
  id: string;
  payMultipleBilling?: boolean;
}

export function ProcessModal(props: ProcessModalProps) {
  const dispatch = useAppDispatch();

  const getAdminSessionState = useAppSelector(selectGetAdminSession);
  const getProductDataState = useAppSelector(selectGetProductData);
  const modalScrollState = useAppSelector(selectModalScroll);

  const [rows, setRows] = useState<GetProductDataModel | undefined>(
    productDataInitialState
  );

  useEffect(() => {
    setRows(undefined);
    if (props.id && props.open) {
      dispatch(getProductData({ orderId: props.id }));
    }

    // if (props.currentTab === 2) {
    //   dispatch(togglePopupScroll());
    // }

    if (props.currentTab === 8) {
      // dispatch(togglePopupScroll());
      dispatch(getDeliverySchedule());
    }

    if (modalScrollState.status === true) {
      dispatch(closeModalScroll());
    }
  }, [dispatch, props.open, props.id, props.currentTab]);

  useEffect(() => {
    if (
      GetProductDataState.success === getProductDataState.status &&
      getProductDataState.data
    ) {
      const updatedProductData = getProductDataState.data.product_data.map(
        (productData) => {
          return {
            category_id: productData.category_id,
            id: productData.id,
            product_id: productData.product_id,
            product_name: productData.product_name,
            uom: productData.uom,
            order_qty: productData.order_qty,
            commited_qty:
              props.currentTab === 0
                ? productData.order_qty
                : productData.commited_qty,
            delivered_qty:
              props.currentTab === 4
                ? productData.commited_qty
                : productData.delivered_qty,
            order_information_id: productData.order_information_id,
            out_of_stock: productData.out_of_stock,
            productCost: productData.productCost,
            total_cost: productData.total_cost,
            manila_cost: productData.manila_cost,
            province_cost: productData.province_cost,
            qty_per_order: productData.qty_per_order,
            // total_cost: (
            //   Number(productData.commited_qty) * parseFloat(productData.cost)
            // ).toFixed(2),
          };
        }
      );

      if (props.currentTab === 0 || props.currentTab === 4) {
        setRows({
          order_information: getProductDataState.data.order_information,
          product_data: updatedProductData,
        });
      } else {
        setRows(getProductDataState.data);
      }
    }
  }, [getProductDataState.data]);

  useEffect(() => {
    if (
      props.currentTab === 0 &&
      props.open &&
      rows?.order_information.store_id
    ) {
      const query = createQueryParams({
        store_id: rows.order_information.store_id,
      });

      if (props.open) {
        dispatch(getStockOrderStores(query));
      }
    }
  }, [props.currentTab, rows?.order_information.store_id, props.open]);

  const handleDownloadTableData = () => {
    const link = `${REACT_APP_DOMAIN_URL}api/stock/export-order-pdf/${props.id}`;
    window.open(link, "_blank");

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setRows(undefined);

    document.body.classList.remove("overflow-hidden");
    props.onClose();
  };

  const processAction = () => {
    const matchingTab =
      getAdminSessionState.data?.admin.user_details.sos_groups.find(
        (tab) =>
          (tab.id === 4 && props.currentTab === 0) ||
          tab.id === props.currentTab + 1
      );

    if (matchingTab && rows) {
      return (
        <StockOrderProcessActionEnabler
          tab={props.currentTab}
          orderId={props.id}
          row={rows}
          onClose={handleCloseModal}
        />
      );
    }

    return null;
  };

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <>
      <div
        className={`fixed inset-0 z-30 flex items-start justify-center ${
          modalScrollState.status === false
            ? "overflow-auto bg-black bg-opacity-30 backdrop-blur-sm"
            : ""
        } `}
      >
        <div className="w-[97%] lg:w-[900px] my-5 rounded-[10px]">
          <div className="bg-secondary rounded-t-[10px] flex items-center justify-between p-4">
            <span className="text-2xl text-white">
              {STOCK_ORDERING_MODAL_TITLE[props.currentTab].label}
            </span>

            <div className="space-x-3">
              <button
                className="text-2xl text-white"
                onClick={handleDownloadTableData}
              >
                <AiOutlineDownload />
              </button>

              <button
                className="text-2xl text-white"
                onClick={handleCloseModal}
              >
                <IoMdClose />
              </button>
            </div>
          </div>

          <div className="p-4 bg-white border-b-2 border-l-2 border-r-2 border-secondary space-y-5">
            {/* {props.payMultipleBilling ? (
              <StockOrderProcessFinancePayBilling
                onClose={handleCloseModal}
                open={props.open}
              />
            ) : (
              <> */}
            {rows ? (
              <>
                <StockOrderTable
                  isCommitedTextFieldAvailable={props.currentTab === 0}
                  isDeliveredQtyAvailable={props.currentTab === 4}
                  enableTableEdit={getAdminSessionState.data?.admin.user_details.sos_groups.some(
                    (perm) => perm.id === 1
                  )}
                  activeTab={props.currentTab}
                  setRows={setRows}
                  rowData={rows}
                />
                {processAction()}
              </>
            ) : (
              <>
                <StockOrderingWatingSkeleton tab={props.currentTab} />
              </>
            )}
            {/* </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
