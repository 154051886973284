import { Divider, FormHelperText } from "@mui/material";
import { PopupModal } from "./popup.modal";
import { FaAngleDown, FaAngleUp, FaCity, FaLandmark } from "react-icons/fa";
import {
  MaterialDateTimeInput,
  MaterialInput,
  MaterialInputAutoComplete,
} from "features/shared/presentation/components";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  useAppDispatch,
  useAppSelector,
  useQuery,
} from "features/config/hooks";
import {
  closeMessageModal,
  openMessageModal,
} from "features/shared/presentation/slices/message-modal.slice";
import {
  insertNewProductCost,
  InsertNewProductCostState,
  selectinsertNewProductCost,
} from "../slices/insert-new-product-cost.slice";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";
import { initialFormState } from "features/sales/presentation/components/sales-utils";
import { selectGetProductCostChangeHistory } from "../slices/get-product-cost-change-history.slice";
import { dateSetup } from "../components/stock-ordering-utils";

interface PiceChangeHistoryProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

interface PriceChangeInputData {
  typeId: number | null;
  productId: string;
  previous: number | null;
  newCost: string | null | undefined;
  effectivityDate: string;
  endDate: string;
}

const typeOption = [
  {
    id: 1,
    name: "Manila",
  },
  { id: 2, name: "Province" },
];

export function PiceChangeHistory(props: PiceChangeHistoryProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const insertNewProductCostState = useAppSelector(selectinsertNewProductCost);
  const getProductCostChangeHistoryState = useAppSelector(
    selectGetProductCostChangeHistory
  );

  const query = useQuery();
  const productId = query.get("productId");
  const manilaCost = query.get("manilaCost");
  const provinceCost = query.get("provinceCost");

  const [openInputNewPrice, setOpenInputNewPrice] = useState(false);
  const [openWithDateOnNewPrice, setOpenWithDateOnNewPrice] = useState(false);

  const [openEffectivityDateCalendar, setOpenEffectivityDateCalendar] =
    useState(false);
  const [openEndDateCalendar, setOpenEndDateCalendar] = useState(false);

  const initialState: PriceChangeInputData = {
    typeId: null,
    productId: "",
    previous: null,
    newCost: null,
    effectivityDate: "",
    endDate: "",
  };

  const [formCostState, setFormCostState] =
    useState<PriceChangeInputData>(initialState);

  useEffect(() => {
    handleOnChange("productId", productId);
    if (formCostState.typeId === 1) {
      handleOnChange("previous", manilaCost);
    } else if (formCostState.typeId === 2) {
      handleOnChange("previous", provinceCost);
    }
  }, [formCostState.typeId]);

  useEffect(() => {
    if (
      insertNewProductCostState.status === InsertNewProductCostState.success
    ) {
      window.location.reload();
    }
  }, [dispatch, insertNewProductCostState]);

  const handlePriceChange = (e: any) => {
    e.preventDefault();

    dispatch(
      openMessageModal({
        useHtml: true,
        message: `<b>Are you sure you want to update the price for this product?</b> <br /><br />
        <b>Form Summary:</b> <br />
        <table style="width: 60%; margin-left: 15px; border-collapse: collapse;">
            <tbody>
                <tr>
                <td>Type:</td>
                <td>${
                  typeOption.find((type) => type.id === formCostState.typeId)
                    ?.name
                }</td>
                </tr>
                <tr>
                <td>Product Id:</td>
                <td>${productId}</td>
                </tr>
                <tr>
                <td>Previous Cost:</td>
                <td>${formCostState.previous}</td>
                </tr>
                <tr>
                <td>New Cost:</td>
                <td>${formCostState.newCost}</td>
                </tr>
                <tr>
                <td>Effectivity Date:</td>
                <td>${
                  formCostState.effectivityDate === ""
                    ? dateSetup(dayjs(new Date()).toString(), true)
                    : dateSetup(formCostState.effectivityDate, true)
                }</td>
                </tr>
                
            </tbody>
              </table>
          `,
        buttons: [
          {
            color: "#CC5801",
            text: "Yes",
            onClick: () => {
              dispatch(insertNewProductCost(formCostState));
              dispatch(closeMessageModal());
            },
          },
          {
            color: "#22201A",
            text: "No",
            onClick: () => {
              dispatch(closeMessageModal());
            },
          },
        ],
      })
    );
  };

  const handleOnChange = (name: string, value: string | number | null) => {
    setFormCostState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetModal = () => {
    setFormCostState(initialState);
    setOpenEffectivityDateCalendar(false);
    setOpenInputNewPrice(false);
    navigate({ pathname: "" });
  };

  return (
    <PopupModal
      noIcon
      open={props.open}
      title={"Price Change History"}
      message={""}
      customButton
      onClose={() => {
        resetModal();
        props.onClose(false);
      }}
      customModalWidth="md:w-[50%] lg:w-[460px]"
      backgroundColor="bg-gray-200"
    >
      <div className="p-2 max-h-72 md:max-h-96 lg:max-h-96 overflow-y-auto space-y-3">
        <div className="space-y-1">
          {/* CURRENT PRICE LIST*/}
          <h2 className="font-[Roboto] text-sm px-2 pt-2">
            Currrent product price
          </h2>
          <div className="flex flex-col bg-white rounded-2xl">
            <div className="flex flex-col px-8 py-2">
              <div className="flex justify-between pb-1">
                <div className="flex items-baseline space-x-1">
                  <FaCity className="text-lg pt-1" />
                  <h3 className="font-[Roboto] text-lg">Manila Price</h3>
                </div>
                <span className="font-[Roboto] text-lg">
                  {Number(manilaCost)?.toFixed(2)} php
                </span>
              </div>
              <Divider />
              <div className="flex justify-between pt-1">
                <div className="flex items-baseline space-x-1">
                  <FaLandmark className="text-lg pt-1" />
                  <h3 className="font-[Roboto] text-lg">Province Price</h3>
                </div>
                <span className="font-[Roboto] text-lg">
                  {Number(provinceCost)?.toFixed(2)} php
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* PRICE CHANGE INPUT*/}
        <div className="space-y-1">
          <h2 className="font-[Roboto] text-sm px-2 pt-2">
            Change current product price
          </h2>
          <div
            className={`flex flex-col bg-white rounded-2xl p-5  ${
              openInputNewPrice ? "space-y-5" : "space-y-0"
            }`}
          >
            <button onClick={() => setOpenInputNewPrice(!openInputNewPrice)}>
              <div className="w-full flex justify-between">
                <span className="text-[Roboto] text-lg">
                  Setup new product price
                </span>
                {openInputNewPrice ? (
                  <FaAngleUp className="text-2xl" />
                ) : (
                  <FaAngleDown className="text-2xl" />
                )}
              </div>
              <FormHelperText>
                Click the dropdown to setup new product price{" "}
              </FormHelperText>
            </button>

            <div
              className={`grid transition-all duration-500 ease-in-out ${
                openInputNewPrice ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
              }`}
            >
              <form
                onSubmit={handlePriceChange}
                className="flex flex-col space-y-2 overflow-hidden"
              >
                <div className="flex flex-col md:flex-row md:space-x-3">
                  <div className="w-full">
                    <div className="flex">
                      <h3>Select your area</h3>{" "}
                      <span className="text-xs text-red-600">*</span>
                    </div>
                    <MaterialInputAutoComplete
                      colorTheme={"black"}
                      size="small"
                      required
                      options={typeOption ?? []}
                      getOptionLabel={(option) => option.name ?? []}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      onChange={(e, selectedItem) => {
                        if (selectedItem !== null) {
                          handleOnChange("typeId", selectedItem.id);
                        } else {
                          handleOnChange("typeId", null);
                        }
                      }}
                      placeholder="Select your area"
                    />
                    <FormHelperText>
                      Choose Between Manila and Province
                    </FormHelperText>
                  </div>

                  <div className="w-full">
                    <div className="flex">
                      <h3>Set new product price</h3>{" "}
                      <span className="text-xs text-red-600">*</span>
                    </div>
                    <MaterialInput
                      colorTheme={"black"}
                      fullWidth
                      required
                      size="small"
                      value={formCostState.newCost}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value) && value.length <= 7) {
                          handleOnChange(e.target.name, value);
                        }
                      }}
                      name={"newCost"}
                      placeholder="New cost"
                    />
                    <FormHelperText sx={{ textTransform: "capitalize" }}>
                      Enter the new{" "}
                      {
                        typeOption.find(
                          (fnd) => fnd.id === formCostState.typeId
                        )?.name
                      }{" "}
                      Product price
                    </FormHelperText>
                  </div>
                </div>

                <div className="w-full">
                  <button
                    type="button"
                    onClick={() =>
                      setOpenWithDateOnNewPrice(!openWithDateOnNewPrice)
                    }
                  >
                    <div className="w-full flex justify-between">
                      <span className="text-[Roboto] text-base">
                        Schedule price increase with effective date
                      </span>
                      {openWithDateOnNewPrice ? (
                        <FaAngleUp className="text-2xl" />
                      ) : (
                        <FaAngleDown className="text-2xl" />
                      )}
                    </div>
                    <FormHelperText>
                      Click the dropdown if you want to setup new price with
                      effectivity date{" "}
                    </FormHelperText>
                  </button>

                  <div
                    className={`grid transition-all duration-500 ease-in-out ${
                      openWithDateOnNewPrice
                        ? "grid-rows-[1fr]"
                        : "grid-rows-[0fr]"
                    }`}
                  >
                    <div className="overflow-hidden">
                      <div className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-3">
                        <div className="w-full">
                          <div className="flex space-x-1">
                            <h3>Effectivity date </h3>
                            <span className="text-xs text-[#666666]">
                              (optional)
                            </span>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <MaterialDateTimeInput
                              colorTheme={"black"}
                              fullWidth
                              value={formCostState.effectivityDate}
                              textFieldValue={formCostState.effectivityDate}
                              openCalendar={openEffectivityDateCalendar}
                              placeholder="Effectivity date"
                              setOpenCalendar={() => {
                                setOpenEffectivityDateCalendar(
                                  !openEffectivityDateCalendar
                                );
                              }}
                              onChange={(value) => {
                                handleOnChange(
                                  "effectivityDate",
                                  dayjs(value)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                    .toString()
                                );
                              }}
                              minDate={new Date()}
                              size="small"
                              readonly
                              iconColor="grey"
                            />
                            <button
                              type="button"
                              onClick={() =>
                                handleOnChange("effectivityDate", "")
                              }
                              className="flex justify-end text-xs text-button font-[Roboto] font-semibold underline underline-offset-2"
                            >
                              Clear effectivity date
                            </button>
                          </div>
                        </div>
                      </div>
                      <FormHelperText>
                        <div>
                          <ul>
                            <li>
                              <strong>Effectivity Date</strong>: Specify an
                              effective date if you want your changes to take
                              effect on a particular date.
                            </li>
                          </ul>
                        </div>
                      </FormHelperText>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-[Roboto] py-1 text-base font-medium text-white capitalize tracking-[0.025rem] bg-button rounded-md"
                >
                  Change price
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* PRICE HISTORY */}{" "}
        <h2 className="font-[Roboto] text-sm px-2 pt-2">Change History</h2>
        <div className="space-y-2">
          {getProductCostChangeHistoryState.data?.length !== 0 ? (
            getProductCostChangeHistoryState.data?.map(
              (productCostChange, index) => (
                <>
                  <div
                    className={`flex flex-col space-y-2 bg-white ${
                      getProductCostChangeHistoryState.data?.length === 1
                        ? "rounded-3xl"
                        : index === 0
                        ? "rounded-t-3xl"
                        : index + 1 ===
                          getProductCostChangeHistoryState.data?.length
                        ? "rounded-b-3xl"
                        : "rounded-md"
                    }`}
                  >
                    <div className="flex justify-between px-8 pt-3">
                      <div className="flex items-baseline space-x-1">
                        {productCostChange.rateId === 1 ? (
                          <FaCity className="text-lg pt-1" />
                        ) : (
                          <FaLandmark className="text-lg pt-1" />
                        )}
                        <h3 className="font-[Roboto] text-lg capitalize">
                          {productCostChange.rateType + " cost"}{" "}
                        </h3>
                      </div>
                      <span className="font-[Roboto] text-xl">
                        {"+ " + productCostChange.newCost + " php"}
                      </span>
                    </div>
                    <div className="flex flex-col px-8 pb-2">
                      <div className="flex flex-row space-x-1">
                        <h3 className="font-[Roboto] text-xs">
                          Effectivity Date:
                        </h3>
                        <span className="font-[Roboto] text-xs">
                          {productCostChange.effectivityDate
                            ? dateSetup(productCostChange.effectivityDate, true)
                            : "N/A"}
                        </span>
                      </div>

                      <div className="flex flex-row space-x-1">
                        <h3 className="font-[Roboto] text-xs">
                          Creation Date:
                        </h3>
                        <span className="font-[Roboto] text-xs">
                          {productCostChange.creationDate
                            ? dateSetup(productCostChange.creationDate, true)
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )
            )
          ) : (
            <div className="flex justify-center text-[Roboto] text-sm space-y-2">
              No cost change yet. {":)"}
            </div>
          )}
        </div>
      </div>
    </PopupModal>
  );
}
