import { useAppDispatch } from "features/config/hooks";
import { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { toggleModalScroll } from "../slices/modalScrollSlice.slice";
import { CgFileAdd } from "react-icons/cg";
import { BsFileEarmarkArrowUpFill } from "react-icons/bs";
import { PiFileFill } from "react-icons/pi";
import { FaFilePdf, FaTrashCan } from "react-icons/fa6";
import { useDropzone } from "react-dropzone";
import { BiSolidFilePdf, BiSolidFileJpg } from "react-icons/bi";
import React from "react";

interface UploadMultipleImageModalProps {
  open: boolean;
  onClose: () => void;
  files: File[] | null;
  setFiles: (files: File[]) => void;
}

export function UploadMultipleImageModal(props: UploadMultipleImageModalProps) {
  const dispatch = useAppDispatch();

  const [uploadedFile, setUploadedFile] = useState<File[]>(props.files ?? []);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    props.setFiles(uploadedFile);
  }, [uploadedFile]);

  const fileTypeChecker = (type: string) => {
    const allowedExtensions = ["pdf", "jpg"];
    const fileExtension = type.split(".").pop()?.toLowerCase();

    if (fileExtension && allowedExtensions.includes(fileExtension)) {
      return true;
    } else {
      setErrorMessage("Invalid file extension. Must be jpg or pdf");
      return false;
    }
  };

  const fileSizeChecker = (size: number) => {
    const maxSize = 2000000; // 2mb

    if (size <= maxSize) {
      return true;
    } else {
      setErrorMessage("Invalid file size. Must be below 2mb");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const newFiles: File[] = [];

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          if (
            e.target?.result &&
            fileTypeChecker(file.name) &&
            fileSizeChecker(file.size)
          ) {
            newFiles.push(file);
            if (newFiles.length === acceptedFiles.length) {
              setUploadedFile((prevFiles) => [...prevFiles, ...newFiles]);
            }
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [uploadedFile, setUploadedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: { "image/jpeg": [".jpg"], "application/pdf": [".pdf"] },
    maxSize: 2000000,
  });

  const deleteFile = (index: number) => () => {
    const newFiles = [...uploadedFile];
    newFiles.splice(index, 1);
    setUploadedFile(newFiles);
  };

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <div className="flex justify-center fixed w-full h-full z-40 -inset-y-5 inset-0 backdrop-blur-sm">
      <div className="w-11/12 md:w-1/3 h-5/6 mt-7 rounded-2xl p-3 bg-paper shadow-md ">
        <div className="overflow-auto h-full p-3">
          <div className="flex flex-col space-y-1  ">
            <div className="flex justify-between ">
              <div className="flex space-x-3">
                <CgFileAdd className="text-3xl" />
                <h1 className="text-black font-[Roboto] tracking-[0.5px] text-2xl ">
                  Upload File/s
                </h1>
              </div>
              <button
                type="button"
                className="text-2xl text-black"
                onClick={() => {
                  dispatch(toggleModalScroll());
                  document.body.classList.remove("overflow-hidden");
                  props.onClose();
                }}
              >
                <IoMdClose />
              </button>
            </div>
            <p className="ml-1 font-[Roboto] text-gray-500">
              Upload single or multiple attachments
            </p>
          </div>

          <div className="flex flex-col space-y-2 w-full py-5 px-1 ">
            <div
              {...getRootProps()}
              className="flex flex-col space-y-3 w-full border-2 border-button bg-button bg-opacity-5  rounded-2xl p-10"
            >
              <input type="file" name="uploaded_file" {...getInputProps()} />

              {isDragActive ? (
                <span className="text-lg text-secondary">
                  Drop the files here ...
                </span>
              ) : (
                <>
                  <BsFileEarmarkArrowUpFill className="self-center text-3xl text-button" />
                  <p className="self-center">Drag & drop your files here or</p>
                  <button className="self-center text-black bg-gray-400 rounded-md py-1 px-5">
                    Choose files
                  </button>
                </>
              )}
            </div>

            <p className="text-gray-500">
              {errorMessage === ""
                ? "Only .pdf and .jpg files. 2mb max file size"
                : errorMessage}
            </p>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-black font-[Roboto] tracking-[0.5px] text-2xl ">
              Uploaded File/s
            </h2>

            {uploadedFile.length !== 0 ? (
              uploadedFile.map((file, fileIndex) => (
                <React.Fragment key={fileIndex}>
                  <UploadedFile
                    name={file.name}
                    size={file.size}
                    type={file.type}
                    onDelete={deleteFile(fileIndex)}
                  />
                </React.Fragment>
              ))
            ) : (
              <h3 className="flex justify-center text-gray-500 ">
                No uploaded files yet
              </h3>
            )}
          </div>

          <p className="flex justify-center mt-5 text-gray-500 text-xs md:text-sm ">
            You can close the modal if you are satisfied with your uploaded
            files
          </p>
        </div>
      </div>
    </div>
  );
}

const UploadedFile = (fileData: {
  name: string;
  size: number;
  type: string;
  onDelete: () => void;
}) => {
  const { name, size, type } = fileData;

  const fileIcon = (css: string) => {
    if (type === "application/pdf") {
      return <BiSolidFilePdf className={css} />;
    } else if (type === "image/jpeg") {
      return <BiSolidFileJpg className={css} />;
    }
  };

  return (
    <div className="flex justify-between border border-gray-300 rounded-lg p-5">
      <div className="flex space-x-3">
        {fileIcon("text-4xl text-gray-500 self-center")}
        <div className="flex flex-col ">
          <span>{name}</span>
          <div className="flex space-x-1">
            <span className="text-gray-500 text-sm">
              {(size / 1000000).toFixed(2)} MB
            </span>
            <span className="text-gray-500 text-sm">|</span>
            <span className="text-gray-500 text-sm">{type} </span>
          </div>
        </div>
      </div>

      <FaTrashCan
        onClick={fileData.onDelete}
        className="text-xl text-gray-500 self-center"
      />
    </div>
  );
};
