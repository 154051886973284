import { useAppDispatch, useAppSelector } from "features/config/hooks";
import { MaterialInputAutoComplete } from "features/shared/presentation/components";
import { useState } from "react";
import { selectGetStockOrderStores } from "../slices/get-store.slice";
import { FaCheckCircle } from "react-icons/fa";
import {
  categoryModel,
  selectedStoreModel,
} from "features/stock-ordering/core/domain/store-and-category.model";
import { STOCK_ORDER_CATEGORY } from "features/shared/constants";
import { StockOrderProductSelector } from "./stock-order-product-selection";
import { OrderTableData } from "features/stock-ordering/core/domain/order-table-row.model";
import { BsArrowRightCircle } from "react-icons/bs";
import { getMinimumTotalAmountData } from "../slices/get-minimum-total-amount.slice";
import { confirmNewOrder } from "../slices/confirm-new-order.slice";

interface PlaceOrderInputModel {
  selectedStore: selectedStoreModel;
  selectedAddress: {
    ship_to_address: string;
    store_id: number | null;
  };
  category: categoryModel;
}

interface AutoCompleteConfigProps {
  options: readonly any[];
  placeholder: string;
  optionLabelName: string;
  onChange: (
    name: string,
    value:
      | PlaceOrderInputModel["selectedStore"]
      | PlaceOrderInputModel["selectedAddress"]
      | PlaceOrderInputModel["category"]
  ) => void;
  value:
    | PlaceOrderInputModel["selectedStore"]
    | PlaceOrderInputModel["selectedAddress"]
    | PlaceOrderInputModel["category"];
}

interface PlaceOrderInputWizardProps {
  openConfirmationState: () => void;
  setSelectedStore: (selectedStoreData: selectedStoreModel) => void;
}

export function PlaceOrderInputWizard(props: PlaceOrderInputWizardProps) {
  const dispatch = useAppDispatch();

  //Fetch data
  const getStores = useAppSelector(selectGetStockOrderStores);

  //Initial State
  const formStateInitialState = {
    selectedStore: { store_id: "", name: "", franchise_type_id: "" },
    selectedAddress: { ship_to_address: "", store_id: null },
    category: { category_id: "", category_name: "" },
  };

  //State variable
  const [formState, setFormState] = useState<PlaceOrderInputModel>(
    formStateInitialState
  );
  const [productData, setProductData] = useState<OrderTableData[]>([]);
  const [activeStep, setActiveSteps] = useState(0);

  // Fetch store data every store id change

  //Component Change  Controller

  const handleConfirmOrder = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(getMinimumTotalAmountData());

    dispatch(
      confirmNewOrder({
        data: {
          selectedStoreId: formState.selectedStore?.store_id,
          selectedAddress: formState.selectedAddress.ship_to_address as string,
          category: formState.category,
          OrderData: productData,
        },
      })
    );
    props.openConfirmationState();
  };

  const handleResetButton = () => {
    setFormState(formStateInitialState);
    setActiveSteps(0);
  };

  const handleInputOnChange = (name: string, value: any) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleAutoCompleteChange = (name: string, value: any) => {
    setActiveSteps((prev) => prev + 1);
    handleInputOnChange(name, value);
  };

  //Input card data
  const inputCardData = [
    {
      idx: 0,
      title: "Choose your store",
      subTitle: "Select the store where you will place your stock orders.",
      name: "selectedStore",
      autoCompleteProps: {
        placeholder: "Select a store...",
        options: getStores.data?.stores ?? [],
        optionLabelName: "name",
        value: formState.selectedStore,
        onChange: (e: any, val: any) => {
          props.setSelectedStore(val);
          handleAutoCompleteChange("selectedStore", val);
        },
      },
    },
    {
      idx: 1,
      title: "Choose store location",
      subTitle:
        "Please choose the address of the store where you want your stocks to be delivered.",
      name: "selectedAddress",
      autoCompleteProps: {
        placeholder: "Enter the store address...",
        options: getStores.data?.address,
        optionLabelName: "ship_to_address",
        value: formState.selectedAddress,
        onChange: (e: any, val: any) =>
          handleAutoCompleteChange("selectedAddress", val),
      },
    },

    {
      idx: 2,
      title: "Choose Product Category",
      subTitle:
        "Choose the type of product category for your stock orders: Frozen Products or Dry Products.",
      name: "category",
      autoCompleteProps: {
        placeholder:
          "Select a product category (e.g., Frozen Products, Dry Products)...",
        options: STOCK_ORDER_CATEGORY,
        optionLabelName: "category_name",
        formState: formState.category,
        onChange: (e: any, val: any) =>
          handleAutoCompleteChange("category", val),
      },
    },
  ];

  // Wizard input Card
  const InputCard = (cardProps: {
    heading: { title: string; subTitle: string };
    autoCompleteProps: AutoCompleteConfigProps;
    name: string;
    active: boolean;
  }) => {
    //data
    const { title, subTitle } = cardProps.heading;
    const { name, active } = cardProps;
    const { options, placeholder, optionLabelName, onChange } =
      cardProps.autoCompleteProps;

    return (
      <div
        className={`flex flex-col space-y-5 bg-white shadow-md rounded-lg p-5 h-fit ${
          !active && "hidden"
        }`}
      >
        <div>
          <div className="flex justify-between">
            <h3 className="text-xl font-[Roboto] font-semibold">{title}</h3>
            <FaCheckCircle
              className={`text-green-600 text-3xl self-center ${
                active && "hidden"
              }`}
            />
          </div>

          <div>
            <p className="text-gray-400">{subTitle}</p>

            <MaterialInputAutoComplete
              colorTheme={"black"}
              required
              fullWidth
              size={"medium"}
              options={options}
              getOptionLabel={(option) => option?.[optionLabelName]}
              isOptionEqualToValue={(option, value) =>
                option?.[optionLabelName] === value?.[optionLabelName]
              }
              onChange={(e, values) => onChange(name, values)}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full space-y-3">
      {activeStep === 3 ? (
        <div className="flex flex-col space-y-5 transition-opacity duration-1000 delay-150 ease-in-out">
          <div className="flex flex-col space-y-3 bg-white shadow-md rounded-lg p-5 h-fit">
            <h3 className="text-[Roboto] font-bold">
              Summary{" "}
              <button
                type="button"
                onClick={handleResetButton}
                className="font-normal underline underline-offset-1 text-xs text-button"
              >
                [reset]
              </button>
            </h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div className="flex space-x-3">
                <p className="text-[Roboto] font-semibold">Store Name:</p>
                <p className="text-gray-600">{formState.selectedStore.name}</p>
              </div>
              <div className="flex space-x-3">
                <p className="text-[Roboto] font-semibold">Category:</p>
                <p className="text-gray-600">
                  {formState.category.category_name}
                </p>
              </div>
              <div className="flex space-x-3 col-span-2">
                <p className="text-[Roboto] font-semibold">Store Address:</p>
                <p className="text-gray-600">
                  {formState.selectedAddress.ship_to_address}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2 bg-white shadow-md rounded-lg p-5 h-fit">
            <div className="flex justify-between">
              <div className="flex flex-col space-y-1 text-[Roboto]">
                <h3 className="font-bold">Product Selection</h3>
                <p className="text-gray-600 text-sm">
                  Transfer all products you want to order
                </p>
              </div>
              <button
                type="button"
                onClick={handleConfirmOrder}
                className={`flex space-x-2 text-button hover:underline hover:underline-offset-2 ${
                  productData.length === 0 && "hidden"
                }`}
              >
                <p className="text-[Roboto] self-center">
                  Proceed to order confirmation
                </p>
                <BsArrowRightCircle className="text-xl self-center" />
              </button>
            </div>
            <StockOrderProductSelector
              category_id={formState.category.category_id}
              selected_store={formState.selectedStore}
              setRows={setProductData}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full px-3">
            <button
              onClick={() => setActiveSteps((prev) => prev - 1)}
              type="button"
              className={`flex space-x-2 text-button font-[Roboto] tracking-[1px] hover:underline hover:underline-offset-2 ${
                activeStep === 0 && "hidden"
              }`}
            >
              <BsArrowRightCircle className="text-xl self-center scale-x-[-1]" />
              <p>Back</p>
            </button>
          </div>
          {inputCardData.map((step, index) => (
            <div
              key={step.idx}
              className={`transition-opacity duration-1000 delay-150 ease-in-out ${
                index === activeStep ? "opacity-100" : "opacity-0"
              }`}
            >
              <InputCard
                heading={{ title: step.title, subTitle: step.subTitle }}
                name={step.name}
                active={index === activeStep}
                autoCompleteProps={
                  step.autoCompleteProps as AutoCompleteConfigProps
                }
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
