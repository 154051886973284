import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "features/config/store";
import { insertNewProductCostDate } from "features/stock-ordering/core/stock-ordering.params";
import {
  insertNewProductCostResponse,
  insertNewProductCostRepository,
} from "features/stock-ordering/data/stock-ordering.repository";

export enum InsertNewProductCostState {
  initial,
  inProgress,
  success,
  fail,
}

interface InitialState {
  status: InsertNewProductCostState;
  message: string;
}

const initialState: InitialState = {
  status: InsertNewProductCostState.initial,
  message: "",
};

export const insertNewProductCost = createAsyncThunk(
  "insertNewProductCost",
  async (param: insertNewProductCostDate, { rejectWithValue }) => {
    try {
      const response: insertNewProductCostResponse =
        await insertNewProductCostRepository(param);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (!error.response) {
          throw error;
        }
        throw rejectWithValue(error.response.data.message);
      }
    }
  }
);

/* Main Slice */
export const insertNewProductCostSlice = createSlice({
  name: "insertNewProductCost",
  initialState,
  reducers: {
    resetinsertNewProductCost: (state) => {
      state.status = InsertNewProductCostState.inProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertNewProductCost.pending, (state) => {
        state.status = InsertNewProductCostState.inProgress;
      })
      .addCase(insertNewProductCost.fulfilled, (state, action) => {
        if (action.payload) {
          const { message } = action.payload;
          state.status = InsertNewProductCostState.success;
          state.message = message;
        }
      })
      .addCase(insertNewProductCost.rejected, (state, action) => {
        state.status = InsertNewProductCostState.fail;
        state.message = action.payload as string;
      });
  },
});

export const selectinsertNewProductCost = (state: RootState) =>
  state.insertNewProductCost;

export const { resetinsertNewProductCost } = insertNewProductCostSlice.actions;
export default insertNewProductCostSlice.reducer;
