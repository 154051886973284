import {
  MaterialInput,
  MaterialInputAutoComplete,
} from "features/shared/presentation/components";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FormHelperText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "features/config/hooks";
import {
  adminInserStoreCreationNewRegion,
  AdminInserStoreCreationNewRegionState,
  selectAdminInserStoreCreationNewRegion,
} from "../slices/admin-insert-store-creation-new-region.slice";
import { selectAdminGetStoreCreationNewRegion } from "../slices/admin-get-store-creation-new-region.slice";
import { getAdminRegionStoreCombinations } from "../slices/get-admin-region-store-combinations.slice";
interface AdminAddNewRegionModalProps {
  open: boolean;
  onClose: () => void;
}

interface FormState {
  storeName: string;
  regionId: number | null;
}

export function AdminAddNewRegionModal(props: AdminAddNewRegionModalProps) {
  const dispatch = useAppDispatch();
  const adminGetStoreCreationNewRegion = useAppSelector(
    selectAdminGetStoreCreationNewRegion
  );

  const adminInserStoreCreationNewRegionState = useAppSelector(
    selectAdminInserStoreCreationNewRegion
  );

  const [formState, setFormState] = useState<FormState>({
    regionId: null,
    storeName: "",
  });

  useEffect(() => {
    if (
      adminInserStoreCreationNewRegionState.status ===
      AdminInserStoreCreationNewRegionState.success
    ) {
      dispatch(getAdminRegionStoreCombinations());

      props.onClose();
    }
  }, [adminInserStoreCreationNewRegionState]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      adminInserStoreCreationNewRegion({
        newRegion: formState.storeName,
        regionId: formState.regionId,
      })
    );
  };

  if (props.open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
    return null;
  }

  return (
    <div className="flex justify-center absolute w-full h-full z-40 backdrop-blur-sm top-0 right-0">
      <div className="w-1/3 h-fit mt-16">
        {/* head  title*/}
        <div className="flex justify-between bg-secondary border border-secondary rounded-t-md p-5">
          <h1 className="text-white text-2xl ">Add New Region</h1>
          <button
            className="text-2xl text-white"
            onClick={() => {
              document.body.classList.remove("overflow-hidden");
              props.onClose();
            }}
          >
            <IoMdClose />
          </button>
        </div>

        {/* body */}
        <div className="flex  w-full bg-paper p-4 border border-secondary">
          <form
            className="flex flex-col w-full space-y-2"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col">
              <p>
                Enter new custom region name:
                <span className="text-red-600">*</span>
              </p>
              <FormHelperText>
                Note: Please follow the store region naming convention listed
                below:
                <ul className="px-3">
                  <li>-NCR (Makati) - WalterMart Makati</li>
                  <li>-NCR (Quezon City) - Robinsons Magnolia</li>
                  <li>-Davao City - NCCC Mall Buhangin</li>
                </ul>
              </FormHelperText>
              <MaterialInput
                colorTheme={"black"}
                required
                fullWidth
                size="small"
                onChange={(e) => {
                  setFormState({ ...formState, storeName: e.target.value });
                }}
                autoComplete="off"
                placeholder="Enter custom region name"
                name="storeName"
              />
            </div>

            <div className="flex flex-col">
              <p>
                Select store region:
                <span className="text-red-600">*</span>
              </p>

              <MaterialInputAutoComplete
                colorTheme={"black"}
                options={adminGetStoreCreationNewRegion.data ?? []}
                getOptionLabel={(option) => option.name}
                size="small"
                placeholder="Store region"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, selectedValue) =>
                  selectedValue !== null &&
                  setFormState({ ...formState, regionId: selectedValue.id })
                }
              />
            </div>

            <button className="bg-button rounded-md text-white p-1">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
