import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/config/store";

interface InitialState {
  status: boolean;
}

const initialState: InitialState = {
  status: false,
};

export const modalScrollSlice = createSlice({
  name: "modalScroll",
  initialState,
  reducers: {
    openModalScroll: (state) => {
      state.status = true;
    },
    closeModalScroll: (state) => {
      state.status = false;
    },
    toggleModalScroll: (state) => {
      state.status = !state.status;
    },
  },
});

export const selectModalScroll = (state: RootState) => state.modalScroll;

export const { openModalScroll, closeModalScroll, toggleModalScroll } =
  modalScrollSlice.actions;

export default modalScrollSlice.reducer;
